<template>
  <v-btn
    :color="isSaved ? 'green' : 'primary'"
    :block="block"
    :variant="getVariant"
    :loading="isSaving"
    :disabled="disabled"
    min-width="120"
    class="save-button"
    @click="onClick"
  >
    <span v-if="isSaved">
      <v-icon start>mdi-check-circle</v-icon>
      {{ $t("common.core.saved") }}
    </span>
    <span v-else>
      <slot name="label">{{ $t("common.core.save") }}</slot>
    </span>
  </v-btn>
</template>

<script>
export default {
  name: "SaveButton",
  props: {
    isSaved: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  computed: {
    getOutlinedButtonColor() {
      return "primary"
    },
    getVariant() {
      return this.$vuetify.theme.current.dark ? "flat" : "tonal";
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.save-button {
  transition: all 0.3s linear;
}
.save-button[disabled] {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>

import authService from "@/services/authService";
import { USER_ROLE } from "@/config/constants.js";
const isWorkAllocationSidebarVisible = function (route) {
  return route.params?.id?.toLowerCase() === authService.getEmailAddress();
};
const WorkAllocationForecast = "work-allocation-forecast";

export default [
  {
    path: "/people",
    name: "people",
    redirect: { name: "user-directory" },
  },
  {
    path: "/people/directory/",
    name: "user-directory",
    redirect: "/people/directory/surname/A",
    component: () => import("@/modules/people/_views/PeopleDashboard.vue"),
    children: [
      {
        path: ":order/:filter",
        name: "user-directory-filtered",
        component: () => import("@/modules/people/_views/UserDirectory.vue"),
        meta: {
          title: "People Insight // Directory",
          hasSidebar: true,
        },
      },
    ],
  },

  /**
   * Forecast Scheduling Routes
   */
  {
    path: "/people/work-allocation/scheduling/",
    name: "work-allocation-scheduling",
    component: () => import("@/modules/people/_views/work-allocation/WorkAllocationSchedule.vue"),
    meta: {
      title: "People Insight // Scheduling",
      hasSidebar: true,
    },
    redirect: { name: "schedule-by-resources" },
    children: [
      {
        path: "resources",
        name: "schedule-by-resources",
        component: () => import("@/modules/people/_components/scheduling/ScheduleByResources.vue"),
        meta: {
          title: "People Insight // Scheduling // Resources",
          hasSidebar: true,
        },
      }
    ]
  },

  /**
   * Work Allocation Management Routes
   */
  {
    path: "/people/work-allocation/management/",
    name: "work-allocation-management",
    component: () =>
      import(
        "@/modules/people/_views/work-allocation/WorkAllocationManagement.vue"
      ),
    meta: {
      title: "People Insight // Work Allocation // Management",
      hasSidebar: true,
    },
    beforeEnter: (_to, _from, next) => {
      if (!authService.hasRole(USER_ROLE["wam"])) {
        next({ path: "/unauthorised" });
      }
      next();
    },
  },

  /**
   * Routes relating to a single person, such as Profile or Career Development
   */
  {
    path: "/people/:id",
    redirect: { name: "people-profile" },
    props: true,
    component: () => import("@/modules/people/_views/PersonShell.vue"),
    children: [
      {
        path: "profile",
        name: "people-profile",
        component: () => import("@/modules/people/_views/UserProfile.vue"),
        props: true,
        meta: {
          title: "People Insight // Profile // {id}",
          hasSidebar: false,
        },
        beforeEnter: (to, _from, next) => {
          to.meta.hasSidebar = isWorkAllocationSidebarVisible(to);
          next();
        },
      },
      {
        path: "blogs",
        name: "people-blogs",
        component: () => import("@/modules/people/_views/PersonBlogs.vue"),
        props: true,
        meta: {
          title: "People Insight // Blogs // {id}",
          hasSidebar: false,
        },
        beforeEnter: (to, _from, next) => {
          to.meta.hasSidebar = isWorkAllocationSidebarVisible(to);
          next();
        },
      },
      {
        path: "work-allocation/career-development/",
        name: "work-allocation-development",
        component: () =>
          import(
            "@/modules/people/_views/work-allocation/CareerDevelopment.vue"
          ),
        meta: {
          title:
            "People Insight // {id} // Work Allocation // Career Interests",
          hasSidebar: false,
        },
        beforeEnter: (to, _from, next) => {
          if (to.params.id.toLowerCase() !== authService.getEmailAddress()) {
            next({ path: "*" });
          }
          to.meta.hasSidebar = isWorkAllocationSidebarVisible(to);
          next();
        },
      },
      {
        path: "work-allocation/forecast",
        name: "work-allocation-forecast",
        component: () =>
          import(
            "@/modules/people/_views/work-allocation/WorkAllocationForecast.vue"
          ),
        meta: {
          title: "People Insight // {id} // Work Allocation // Forecast",
          hasSidebar: false,
        },
        beforeEnter: (to, _from, next) => {
          to.meta.hasSidebar = isWorkAllocationSidebarVisible(to);
          next();
        },
      },
      {
        path: "partner-capability",
        name: "partner-capability",
        component: () =>
          import(
            "@/modules/people/_views/partner-capability/PartnerCapability.vue"
          ),
        props: true,
        meta: {
          title: "People Insight // Capability",
          hasSidebar: true,
        },
      },
    ],
  },
  // Redirect a non-ID'd URL to the version for the logged-in user
  {
    path: "/people/work-allocation/forecast",
    redirect: {
      name: WorkAllocationForecast,
      params: { id: authService.getEmailAddress() },
    },
  },
];
